.BasicInfo{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top:50px;
}

.BasicInfo>.UserName{
    font-size: 30px;
    margin: 10px;
}

.UserProfilePage{
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    margin-top:100px;
}

.FriendListItem{
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.FriendListItem>.UserName{
    margin-left: 10px;
}