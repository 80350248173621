.PageName{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    background: white;   
    margin-bottom: 20px;
    width: 100%;
}

.UserPage>.ant-layout>.ant-layout-content>div>.HotTagsFooter{
  margin-top:5px;
}

.UserPage>.ant-layout>.ant-layout-header{
    background: #F2F7FA !important;
    margin-bottom: 10px;
    margin-top: 10px;
}

.ant-layout-sider-children{
    height: 100vh;
    background: #F2F7FA !important;
}
.HotTagsSider{
    display: flex;
    flex-direction: column;
    height: 100% !important;

}
.ant-layout-sider.ant-layout-sider-dark{
    height: 500vh!important;
    background: #F2F7FA !important;
}


.ant-layout>.ant-layout-has-sider{
        background: white;
        width: 100%;
        height: auto;
        
}


.HotTagsSider>.ant-tag.ant-tag-checkable{
    
    margin: 10px !important;
   
}


.HotTagsSider>.ant-tag.ant-tag-checkable.ant-tag-checkable-checked{
  
    margin: 10px !important;
   
}




.UserPage>.ant-layout>.ant-layout-content{
    background: white !important;
    height: 5vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center ;
    align-items: center;
      
      
}


.UserPage>.ant-layout>.ant-layout{
    background: white !important;
    height: 5vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center ;
    align-items: center;
      
      
}


.ant-layout-footer{
    margin-top: 10px;
    height: 5px !important;
    border-bottom: 5px solid #F2F7FA;
}

.ContentResList.ant-layout-content{
    
    height: 2000px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.ant-menu-submenu-title{
    
}