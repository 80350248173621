body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.App>div>.ant-layout>.ant-layout-footer{
  height: 50px !important;

}

.App>div>.ant-layout>.ant-layout-header{
  margin-top :10px;
}
.ant-layout-header{
  background: white !important;
  display:flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.ant-layout-footer{
  background: white !important;
  display: flex;
  padding: 5px !important;
  height: 50px !important;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.ant-layout-content{
  background: white !important;
  height: 5vh !important;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: left ;
  align-items: center;
}
.ant-input-search.ant-input-affix-wrapper{
  margin-bottom: 8px;
  width: 42% !important;

}
.ant-tag.ant-tag-checkable{
  font-size: 15px;
}


.HotTagsFooterBottomContainer{
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 5px;
    background: #F2F7FA;
}


.Logo{
  width: 150px;
  height: 50px;
  margin-bottom: 5px;
  margin-left: 360px;
}

.ant-layout{
  background: white !important;
}

.App>div>.ant-layout>.ResultListContainer{
  margin-left: 10px;
}
.HotTags>.ant-tag.ant-tag-checkable.ant-tag-checkable-checked{
      background: none !important;
      color: rgb(48, 114, 236) !important;   
      font-weight: bold; 
}
.HotTags>.ant-tag.ant-tag-checkable.ant-tag-checkable-checked::-moz-selection{
  background:white !important;
  color: rgb(48, 114, 236) !important;    
}
.HotTags>.ant-tag.ant-tag-checkable.ant-tag-checkable-checked::selection{
  background:white !important;
  color: rgb(48, 114, 236) !important;    
}
.HotTags>.ant-tag.ant-tag-checkable::-moz-selection{
  background:white !important;
  color: rgb(48, 114, 236) !important;    
}
.HotTags>.ant-tag.ant-tag-checkable::selection{
  background:white !important;
  color: rgb(48, 114, 236) !important;    
}

.HotTagsFooter>.ant-tag.ant-tag-checkable.ant-tag-checkable-checked{
  font-weight: bold;
  background: white !important;
  color: black !important;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.TextEditorField{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    line-height: 20px;
    vertical-align: text-bottom;

}


.RichTextEditorContainer>span{
    display: block;

}



.TextEditorField>span{
        vertical-align: baseline;
        line-height: 20px;
}

.TextEditorField>span:focus{
    outline: none;
}
.ResultListContainer{
  
    width: 70%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-items: center;
   align-content: center;
   justify-content: center;
   margin-bottom: 80px;
   margin-top: 10px;
   
}

.ant-spin-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    align-self:center;
}

.ant-list-item{
   width: 80%;
   min-width: 600px;
   display: flex;
   flex-direction: column;
    align-content:flex-start;
    justify-content: flex-start;
    align-items: flex-start !important;
    justify-items: flex-start;
}
.ant-list-item-content{
    text-align: left !important;
}

.ant-list-item-meta-title{
    text-align: left !important;
    font-size: 18px !important;

}

.ant-card-meta-title{
    text-overflow : visible !important;
    overflow: visible !important;
    
    word-wrap: break-word;
    
}
.ant-card-meta-title>div{
  
    display: flex;
    color: rgb(48, 114, 236);
    word-wrap: break-word;
    white-space: normal;
    white-space: initial;
}

.ant-card-meta{
    margin-bottom: 10px !important;
}
.ant-list-item-meta-title>a{
    color:blue !important;

}
.ant-list-item-meta-description>a{
    color: black;
  
  
}

.ant-list-item-meta-description{
    text-align: left !important;
}
.ResultList_username{
    color:#4FAD63;
}

.sc-bdVaJa{
    background: white !important;
    color: white !important;
    box-shadow: none;
}

pre {
    
  
    white-space: pre-wrap; 
    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
   
}

.ant-card-actions{
    background: white !important;
    border-top: none !important;
}
.ant-card-actions>li{
    background: white !important;
    margin: 0 !important;
    border: none !important;
  

}

.ant-select-selection.ant-select-selection--single{
    border: none !important;

}

.ant-card-body{
    padding-top: 10px !important;
}

.ant-card.ant-card-bordered.ant-card-wider-padding.ant-card-padding-transition{
        border:none !important;
}

.PageName{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    background: white;   
    margin-bottom: 20px;
    width: 100%;
}

.UserPage>.ant-layout>.ant-layout-content>div>.HotTagsFooter{
  margin-top:5px;
}

.UserPage>.ant-layout>.ant-layout-header{
    background: #F2F7FA !important;
    margin-bottom: 10px;
    margin-top: 10px;
}

.ant-layout-sider-children{
    height: 100vh;
    background: #F2F7FA !important;
}
.HotTagsSider{
    display: flex;
    flex-direction: column;
    height: 100% !important;

}
.ant-layout-sider.ant-layout-sider-dark{
    height: 500vh!important;
    background: #F2F7FA !important;
}


.ant-layout>.ant-layout-has-sider{
        background: white;
        width: 100%;
        height: auto;
        
}


.HotTagsSider>.ant-tag.ant-tag-checkable{
    
    margin: 10px !important;
   
}


.HotTagsSider>.ant-tag.ant-tag-checkable.ant-tag-checkable-checked{
  
    margin: 10px !important;
   
}




.UserPage>.ant-layout>.ant-layout-content{
    background: white !important;
    height: 5vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center ;
    align-items: center;
      
      
}


.UserPage>.ant-layout>.ant-layout{
    background: white !important;
    height: 5vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center ;
    align-items: center;
      
      
}


.ant-layout-footer{
    margin-top: 10px;
    height: 5px !important;
    border-bottom: 5px solid #F2F7FA;
}

.ContentResList.ant-layout-content{
    
    height: 2000px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.ant-menu-submenu-title{
    
}
.CommentlistContainer>.ant-list.ant-list-split>.ant-spin-nested-loading>.ant-spin-container{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    margin-left: 20px;
    
}
.PostTitle{
    margin-left:50px;
}

.PageName_OtherUserPageView{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: left;
    font-size: 25px;
    background: white;   
    margin-bottom: 20px;
    width: 100%;
}
.FollowButton{
    margin-left: 400px;
    margin-right: 200px;
}
.PostTranslateItem{
    display: flex;
    flex-direction: row;
}
.Post{
    flex:1 1;
}
.BasicInfo{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top:50px;
}

.BasicInfo>.UserName{
    font-size: 30px;
    margin: 10px;
}

.UserProfilePage{
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    margin-top:100px;
}

.FriendListItem{
    display: flex;
    flex-direction: row;
    margin: 10px;
}

.FriendListItem>.UserName{
    margin-left: 10px;
}
.NetFeedHeader{
    display: flex;
    flex-direction: column;
    
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top:100px;
}

