.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.App>div>.ant-layout>.ant-layout-footer{
  height: 50px !important;

}

.App>div>.ant-layout>.ant-layout-header{
  margin-top :10px;
}
.ant-layout-header{
  background: white !important;
  display:flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.ant-layout-footer{
  background: white !important;
  display: flex;
  padding: 5px !important;
  height: 50px !important;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.ant-layout-content{
  background: white !important;
  height: 5vh !important;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: left ;
  align-items: center;
}
.ant-input-search.ant-input-affix-wrapper{
  margin-bottom: 8px;
  width: 42% !important;

}
.ant-tag.ant-tag-checkable{
  font-size: 15px;
}


.HotTagsFooterBottomContainer{
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 5px;
    background: #F2F7FA;
}


.Logo{
  width: 150px;
  height: 50px;
  margin-bottom: 5px;
  margin-left: 360px;
}

.ant-layout{
  background: white !important;
}

.App>div>.ant-layout>.ResultListContainer{
  margin-left: 10px;
}
.HotTags>.ant-tag.ant-tag-checkable.ant-tag-checkable-checked{
      background: none !important;
      color: rgb(48, 114, 236) !important;   
      font-weight: bold; 
}
.HotTags>.ant-tag.ant-tag-checkable.ant-tag-checkable-checked::selection{
  background:white !important;
  color: rgb(48, 114, 236) !important;    
}
.HotTags>.ant-tag.ant-tag-checkable::selection{
  background:white !important;
  color: rgb(48, 114, 236) !important;    
}

.HotTagsFooter>.ant-tag.ant-tag-checkable.ant-tag-checkable-checked{
  font-weight: bold;
  background: white !important;
  color: black !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}