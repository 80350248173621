.PostTitle{
    margin-left:50px;
}

.PageName_OtherUserPageView{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: left;
    font-size: 25px;
    background: white;   
    margin-bottom: 20px;
    width: 100%;
}
.FollowButton{
    margin-left: 400px;
    margin-right: 200px;
}
.PostTranslateItem{
    display: flex;
    flex-direction: row;
}
.Post{
    flex:1;
}