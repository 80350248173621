.TextEditorField{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    line-height: 20px;
    vertical-align: text-bottom;

}


.RichTextEditorContainer>span{
    display: block;

}



.TextEditorField>span{
        vertical-align: baseline;
        line-height: 20px;
}

.TextEditorField>span:focus{
    outline: none;
}