.ResultListContainer{
  
    width: 70%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-items: center;
   align-content: center;
   justify-content: center;
   margin-bottom: 80px;
   margin-top: 10px;
   
}

.ant-spin-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    align-self:center;
}

.ant-list-item{
   width: 80%;
   min-width: 600px;
   display: flex;
   flex-direction: column;
    align-content:flex-start;
    justify-content: flex-start;
    align-items: flex-start !important;
    justify-items: flex-start;
}
.ant-list-item-content{
    text-align: left !important;
}

.ant-list-item-meta-title{
    text-align: left !important;
    font-size: 18px !important;

}

.ant-card-meta-title{
    text-overflow : visible !important;
    overflow: visible !important;
    
    word-wrap: break-word;
    
}
.ant-card-meta-title>div{
  
    display: flex;
    color: rgb(48, 114, 236);
    word-wrap: break-word;
    white-space: initial;
}

.ant-card-meta{
    margin-bottom: 10px !important;
}
.ant-list-item-meta-title>a{
    color:blue !important;

}
.ant-list-item-meta-description>a{
    color: black;
  
  
}

.ant-list-item-meta-description{
    text-align: left !important;
}
.ResultList_username{
    color:#4FAD63;
}

.sc-bdVaJa{
    background: white !important;
    color: white !important;
    box-shadow: none;
}

pre {
    
  
    white-space: pre-wrap; 
    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
   
}

.ant-card-actions{
    background: white !important;
    border-top: none !important;
}
.ant-card-actions>li{
    background: white !important;
    margin: 0 !important;
    border: none !important;
  

}

.ant-select-selection.ant-select-selection--single{
    border: none !important;

}

.ant-card-body{
    padding-top: 10px !important;
}

.ant-card.ant-card-bordered.ant-card-wider-padding.ant-card-padding-transition{
        border:none !important;
}
